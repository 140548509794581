<template lang="">
    <div>
        <h6 class="mt-5">Mülk Konum Bilgisi</h6>
        <iframe class="mt-5 mb-5" src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d12047.795850020088!2d29.1132!3d40.9825984!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x14cac7bb921f7291%3A0x1ea9ab03aa96a2b1!2zWmUgWWF0xLFyxLFtIEhvbGRpbmcgQS7Fni4!5e0!3m2!1str!2str!4v1690131674785!5m2!1str!2str" width="100%" height="450" style="border:0;" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
    </div>
</template>
<script>
export default {
    
}
</script>
<style lang="">
    
</style>